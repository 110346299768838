import React, { useEffect, useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import NumberFormat from 'react-number-format'
import SeoComponent from '../components/SeoComponent'
import LayoutComponent from '../components/LayoutComponent'
import Img from 'gatsby-image'

const Forbrukslan = props => {

  const title = 'Beste forbrukslån i Norge (2021)'

  const data = useStaticQuery(graphql`
        query {
          data: markdownRemark(fileAbsolutePath: {regex: "/(forbrukslan.md)/"}) {
            id
            html
            frontmatter {
              image
            }
          }
          allLoansJson {
            edges {
              node {
                beskrivelse
                lanebelop
                eksempel
                aldersgrense
                utbetaling
                navn
                url
                src {
                  childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
              }
            }
         }
        }
      `)

  return (
    <LayoutComponent bodyClass="page-services">
      <SeoComponent
        description="Finn de beste forbrukslånene i Norge (2021). Vår favoritt er forbrukslånet fra Bank Norwegian."
        title={title} />
      <div className="main">
        <div className="container pt-4">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <h1>
                {title}
              </h1>
              <p>
                Se hvilke forbrukslån som er best for deg.
              </p>
            </div>
            {data.data.frontmatter.image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img className="intro-image" src={data.data.frontmatter.image} />
              </div>
            )}
          </div>
          <ul>
            {data.allLoansJson.edges.map((it) => {
              return (
                <li style={{ listStyle: 'none', borderTop: '1px solid #eaeffe' }}
                    className={'p-2'}>
                  <div className={"forbruk-card"}>
                    <div>
                      <Img className="forbruk-image" fluid={it.node.src.childImageSharp.fluid} />
                    </div>
                    <div className="forbruk-data">
                      <div className="forbruk-cell">
                        <div style={{ color: 'grey' }}>Lånebeløp</div>
                        {it.node.lanebelop}
                      </div>
                      <div className="forbruk-cell">
                        <div style={{ color: 'grey' }}>Aldersgrense</div>
                        {it.node.aldersgrense + ' år'}
                      </div>
                    </div>
                    <div>
                      <div className="text-center">
                        <a target="__blank" href={it.node.url}
                           className={'green-button big-button px-4 py-1'}>
                          Søk >
                        </a>
                        <p className="annonse">Annonselenke</p>
                      </div>
                    </div>
                  </div>
                  <div className="pt-2"
                       style={{ textAlign: 'center', fontStyle: 'italic', color: 'grey' }}>
                    {it.node.navn + ' forbrukslån: ' + it.node.eksempel}
                  </div>
                </li>
              )
            })}
          </ul>
          {<div className="justify-content-start pb-2 container">
            <div className="pt-6">
              <div dangerouslySetInnerHTML={{ __html: data.data.html }} />
            </div>
          </div>}
        </div>
      </div>
    </LayoutComponent>
  )
}
export default Forbrukslan
